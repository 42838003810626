import React from "react";
import SEO from "@components/seo";
import Layout from "@components/layout";
import Footer from "@components/footers/dark";
import MenuBar from "@components/menuBar/MenuBar";
import TabBar from "./TabBar";
import { Link } from "gatsby";

function Physical() {
  return (
    <>
      <SEO title="Physical Terms and Conditions" />
      <MenuBar/>
      <Layout>
        <TabBar tabKey={"physical"}/>
        <Content />
      </Layout>
      <Footer/>
    </>
  )
}

const Content = () => (
  <div className="relative py-16 bg-white overflow-hidden">
    <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
      <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
        <svg className="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
        </svg>
        <svg className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
        </svg>
        <svg className="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
        </svg>
      </div>
    </div>
    <div className="relative px-4 sm:px-6 lg:px-8">
      <div className="text-lg max-w-prose mx-auto">
        <h1>
          <span className="block text-base text-center text-highlight font-semibold tracking-wide uppercase">Waivpay</span>
          <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Physical Gift Card Terms &amp; Conditions
          </span>
        </h1>
        <p className="mt-8 text-xl text-gray-500 leading-8">
          These terms and conditions apply to use of the WAIVPAY (gift card), issued by WAIVPAY LTD ABN 29 630 927 105 & GiVV Technologies Ltd ABN 91 143 010 048 (we, us, our). In these terms and conditions ‘you’ are the card purchaser or user. If you acquire the card you will have a contract with us. If another person wants to use your gift card, you must tell them that they are, and they must agree, to be bound by these terms and conditions. The card remains our property. NSW law applies to these terms and conditions. By using your gift card you unconditionally submit to the exclusive jurisdiction of the courts of Sydney, New South Wales.
        </p>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <ol className="list-outside">
          <li>The Card is a prepaid eftpos debit card that can be used for purchasing goods and services at retailers in Australia where prepaid EFTPOS debit cards are accepted for electronic transactions. The card cannot be used to obtain or redeem cash (for example at ATMs or over the counter at financial institutions or cash out through EFTPOS terminals) and cannot be used for making direct debit, recurring or regular instalment payments. The card is not a credit card and nor is it linked to a deposit account with us. Some merchants may choose not to accept prepaid cards. Merchants or other providers may impose limits on the use of your card. We are not responsible if a merchant refuses to accept a card. Each participating merchant who accepts the card does so in accordance with these terms and conditions and any applicable law and subject always to the individual trading terms of each participating merchant.</li>
          <li>Gift cards will be activated within 48hours of purchase and cannot be redeemed for cash, reloaded, returned for a refund, have their balances consolidated to a new gift card, or be replaced or re-instated after expiry and are not legal tender, account cards, debit or credit cards or securities. The card cannot be used to make online purchases.</li>
          <li>The card is valid from the date of activation. Australian laws require all gift cards purchased after 1st November 2019 hold a 36 month expiry. There are exceptions to the laws and therefore the expiry period for your card may vary. The relevant expiry period is printed on the back of your card and you can also check the expiry date of your gift card and the card balance at <Link to="/balance-check">https://www.waivpay.com/balance&#8209;check</Link>. It is your responsibility to record the date of purchase of the card and the expiry date. After the gift card has expired, it is no longer valid. All transactions will be declined. Unused value will not be refunded and will become our property. We will not give you any notice before this happens.</li>
          <li>You acknowledge that we may allow access to the services offered on our website to anyone who enters a valid card number. It does not offer you any protection or security if your card is lost, stolen or damaged. Remember to treat your card like cash. You are solely responsible for any loss caused by use of your card and PIN by you or any other person, including any use not approved or contemplated by you. Your card will not be replaced if misused, lost, stolen or damaged. If you notice any error or suspect any unauthorised use relating to the card, you should notify WAIVPAY LTD immediately on 1300 764 721 during business hours or alternatively you can send an email to support@waivpay.com.</li>
          <li>You may use the card as often as you like until the available balance is spent or the card has expired. The available balance cannot be exceeded. Where a purchase exceeds the available balance, you need to pay the excess using another payment method, if the merchant agrees. The Card can be used on more than one occasion but cannot be used to make transactions that exceed the available balance.</li>
          <li>If you have a query or complaint about the gift card, please raise it through any of our contact channels. We will handle all complaints according to our internal dispute resolution procedure. We are not liable for the availability, quality or fitness for purpose of any goods or services purchased with the gift card. Any dispute about goods or services purchased with the gift card must be resolved directly with the merchant. Except for rights which cannot be excluded, any conditions or warranties implied or imposed by legislation or otherwise are excluded from these conditions of use. To the extent permitted by law, are liability is limited to replacing faulty gift cards. If you think there has been a mistaken transaction on your gift card contact the merchant where the mistake was made. We are unable to reverse transactions approved or processed by any merchant.</li>
          <li>The card non-reloadable. Funds cannot be returned to the card if a purchase needs to be returned. Every merchant will handle returns as per their own in-store policies. The merchant may issue a cash refund instead, issue an in-store credit or process the refund to another card. We are not responsible for returns or credits off of purchases.</li>
          <li>Our contact channels are calls to 1300 764 721, email support@waivpay.com or online at www.waivpay.com.</li>
          <li>We may cancel any gift card, or the gift card scheme, for any reason at any time without notice. If so, we may either provide a refund or a replacement gift card of equivalent value unless we reasonably suspect fraud in relation to a gift card. The gift card remains our property.</li>
          <li>We may block, delay, freeze or refuse any transactions where We, in our sole opinion, considers reasonable grounds exist to believe that the relevant transactions are fraudulent, in breach of the AML Legislation, the operating rules or any other relevant law.</li>
          <li>Where transactions are blocked, delayed, frozen or refused by Us, We are not liable for any loss suffered by it or any of the cardholders or other third parties arising directly or indirectly as a result of Us taking this action. Cancellation fees and administration costs will be applied to the cardholder if the gift card is cancelled where WAIVPAY LTD in its sole opinion considers reasonable grounds exist to believe that the relevant transactions are fraudulent, in breach of the AML Legislation, the operating rules or any other relevant law.</li>
          <li>We will monitor all transactions that arise pursuant and in accordance with its obligations imposed on it in accordance with the AML Legislation and/or the operating rules.</li>
          <li>It is Unreasonable Use of the gift card service where your use of the service/promotion is reasonably considered by Us to be fraudulent, to be contrary to the way the service is intended to be used (“Intended Use”), or to adversely affect the contract with our Client(s).
            <p>
              Examples of unreasonable use include:
            </p>
            <ul className="list-outside">
              <li>
                Using the service for the purposes of resale, resupply or commercial exploitation;
              </li>
              <li>
                Using the service for credit card loyalty point manipulation.
              </li>
            </ul>
          </li>
        </ol>
        <p>
          Information will be disclosed to third parties about the card, or transactions made with the card, whenever allowed by law, and also where necessary to operate the card and process transactions. A full privacy policy can be viewed at www.waivpay.com/privacy-policy.
        </p>
        <p>
          We reserve the right to change these terms and conditions at any time. Except where we are required by a law or a code to do so, you will not receive advance personal notice of such changes. Changes will be notified via www.waivpay.com or by using another method that the law or relevant code allows or requires us to use. The current version of these conditions of use is available at www.waivpay.com. If we fail to exercise or delay in exercising any of our rights under these conditions of use, that failure or delay does not constitute a waiver of our rights.
        </p>
      </div>
    </div>
  </div>
);

export default Physical;
